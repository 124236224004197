body {
  margin: 0;
  padding: 0;
}

#html-render a {
  text-decoration: underline;
  text-decoration-color: #266fcf;
  color: #266fcf;
}

#banner-cookie #html-render a {
  color: #fff;
  text-decoration-color: #fff;
}

#html-render td strong {
  font-weight: bold;
}

#html-render td {
  text-align: center;
  padding: 10px 20px;
}

#html-render table {
  border-spacing: 0;
  border: 1px solid rgb(88, 85, 85);
  border-collapse: collapse;
}
